import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-781be390"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "state_text" }
const _hoisted_4 = { class: "msg" }

import {reactive, ref, watch} from "vue";

export default {
  props: {
  state:Number,
  msg:String,
},
  setup(__props) {

const props = __props


// let emits = defineEmits(['againWrite'])
let stateSrc = ref()//状态图片
let stateText1 = ref()//状态文字1
let stateText2 = ref()//状态文字2
watch(()=>props.state,(val)=>{
  type()
})
// 个人用户信息身份认证 -1审核中，1成功，2失败  

//-1,-2 审核失败，0未认证，1审核中，2审核成功
const type = ()=>{
  if(props.state == -1 || props.state == -2){
    stateSrc.value = 'fail'
    stateText1.value = '已驳回'
    stateText2.value = `您提交的认证信息已驳回，原因是：${props.msg || '条件'}不符合，您可以修改后再次申请认证`
  }
  if(props.state == 1){
    stateSrc.value = 'check'
    stateText1.value = '审核中...'
    stateText2.value = '请等待平台审核'
  }
  if(props.state == 2){
    stateSrc.value = 'success'
    stateText1.value = '审核已通过'
    stateText2.value = '认证成功'
  }
}
type()

return (_ctx, _cache) => {
  return (__props.state!==0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["state_msg flex_r", __props.state==1?'check':__props.state==2?'success':'fail'])
      }, [
        _createElementVNode("img", {
          src: require('@/assets/user/'+_unref(stateSrc)+'.png'),
          alt: "",
          class: "state_img"
        }, null, 8, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(stateText1)), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(stateText2)), 1)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

}